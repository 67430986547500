import React from 'react';
import useFilterDataAsUrl from '../../../../hooks/useFilterDataAsUrl';
import { ModalProvider } from '../../../../context/modal';
import { UtilitiesProvider } from '../../../../context/utilities';
import MessageLive from '../../../../components/custom_messages/MessageLive';
import { fetchAppointmentEmail } from '../../../../components/custom_messages/apis';

const AppointmentEmail = ({ locale = 'en' }) => {
  const [, params] = useFilterDataAsUrl();
  const { secured_message_id: id } = params || {};

  const loadData = (updatedLocale) =>
    fetchAppointmentEmail(id, updatedLocale || locale);

  return (
    <div>
      <UtilitiesProvider>
        <ModalProvider>
          <MessageLive
            type="Secure"
            mode="live"
            defaultLocale={locale}
            handleLoadData={loadData}
          />
        </ModalProvider>
      </UtilitiesProvider>
    </div>
  );
};

export default AppointmentEmail;

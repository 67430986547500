import React from 'react';

const Label = ({
  input,
  label,
  showRequiredLabel,
  labelClass,
  subLabel,
  subLabelClass,
  icon,
  htmlFor,
  type,
}) => (
  <React.Fragment>
    {(label || type === 'checkbox') && (
      <label
        id={`field-label-${input.name.replace(/[^a-zA-Z0-9_]/g, '_')}`}
        className={labelClass || 'label-block'}
        htmlFor={htmlFor}
      >
        {showRequiredLabel ? <span className="required-star">*</span> : null}{' '}
        {icon &&
          (icon.src ? <img alt="" src={icon.src} style={icon.style} /> : icon)}
        <span dangerouslySetInnerHTML={{ __html: label }} />
        {subLabel && (
          <div>
            <span
              className={`sub-label ${subLabelClass}`}
              dangerouslySetInnerHTML={{ __html: subLabel }}
            />
          </div>
        )}
      </label>
    )}
  </React.Fragment>
);

export default Label;

import React from 'react';
// import sanitizeHtml from 'sanitize-html';

const CustomHtmlPrompt = (props) => {
  const { label } = props;

  // To support stylised prompts with tags like strong, br etc
  return <span dangerouslySetInnerHTML={{ __html: label }} />;
};

export default CustomHtmlPrompt;

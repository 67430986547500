import React, { useEffect } from 'react';
import Switch from 'react-bootstrap-switch';
import Label from './Label';
import wrapperField from './wrapperField';

// NOTE: custom styles for this component are defined in app/javascript/components/inputs/toggleSwitch/index.module.scss
// there is also a 'pull-right' wrapper for this switch (?)

const toggleSwitch = ({
  input,
  label,
  defaultValue,
  disabled,
  onText,
  offText,
  onColor,
  wrapperClass,
  labelWidth,
  handleWidth,
  mainLabel,
  labelClass,
  subLabel,
  subLabelClass,
  skipDefault = false,
  bsSize = '',
  meta: { pristine, touched, error, warning },
  textOnly,
}) => {
  useEffect(() => {
    if (pristine && defaultValue != undefined && !skipDefault) {
      input.onChange(String(defaultValue) == 'true');
    }
  });

  return (
    <React.Fragment>
      <Label
        {...{ input, label: mainLabel, labelClass, subLabel, subLabelClass }}
      />
      <span className={`main-field-cont ${textOnly ? 'text-only' : ''}`}>
        <Switch
          {...input}
          disabled={disabled}
          onChange={(el, state) => input.onChange(state)}
          labelText={label}
          animate={false}
          onText={onText}
          offText={offText}
          inverse
          bsSize={bsSize}
          onColor={onColor}
          wrapperClass={wrapperClass}
          labelWidth={labelWidth}
          handleWidth={handleWidth}
        />
        {touched &&
          ((error && <span className="error">{error}</span>) ||
            (warning && <span>{warning}</span>))}
      </span>
    </React.Fragment>
  );
};

export default wrapperField(toggleSwitch);

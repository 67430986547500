import React from 'react';
import Secure from './base_templates/Secure';

const Base = ({ mode, type, locale, handleChangeLocale, children }) =>
  type === 'Secure' ? (
    <Secure mode={mode} locale={locale} handleChangeLocale={handleChangeLocale}>
      {children}
    </Secure>
  ) : null;

export default Base;

import { useState, useEffect } from 'react';
import { throttle } from '../utilities';

function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    windowWidth: window.innerWidth,
    windowHeight: window.innerHeight,
  });
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        windowWidth: window.innerWidth,
        windowHeight: window.innerHeight,
      });
    }

    const throttledHandleResize = throttle(handleResize, 100);

    window.addEventListener('resize', throttledHandleResize);
    handleResize();
    return () => window.removeEventListener('resize', throttledHandleResize);
  }, []);
  return windowSize;
}

export default useWindowSize;

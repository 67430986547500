import React, { useEffect, createRef } from 'react';
import clsx from 'clsx';
import style from './modal.module.scss';

const modalRef = createRef();
const focusableElementsList =
  "a[href]:not([tabindex='-1']), area[href]:not([tabindex='-1']), input:not([disabled]):not([tabindex='-1']), select:not([disabled]):not([tabindex='-1']), textarea:not([disabled]):not([tabindex='-1']), button:not([disabled]):not([tabindex='-1']), iframe:not([tabindex='-1']), [tabindex]:not([tabindex='-1']), [contentEditable=true]:not([tabindex='-1'])";
const DesktopModal = ({ children, footer, closeModal, customProps }) => {
  useEffect(() => {
    const bind = (e) => {
      if (e.keyCode !== 27) {
        return;
      }
      if (
        document.activeElement &&
        ['INPUT', 'SELECT'].includes(document.activeElement.tagName)
      ) {
        return;
      }
      closeModal();
    };

    document.addEventListener('keyup', bind);
    return () => document.removeEventListener('keyup', bind);
  }, [children, closeModal]);

  useEffect(() => {
    const focusableModalElements = modalRef.current.querySelectorAll(
      focusableElementsList,
    );
    const firstElement = focusableModalElements[0];
    const currentElement = document.activeElement;
    if (firstElement) firstElement.focus();
    return () => currentElement && currentElement.focus();
  }, []);
  useEffect(() => {
    const element = document.body;
    element.classList.add('modal-open');
    return () => element.classList.remove('modal-open');
  }, []);

  const handleTabKey = (e) => {
    const focusableModalElements = modalRef.current.querySelectorAll(
      focusableElementsList,
    );
    const firstElement = focusableModalElements[0];
    const lastElement =
      focusableModalElements[focusableModalElements.length - 1];

    if (!e.shiftKey && document.activeElement === lastElement) {
      firstElement.focus();
      return e.preventDefault();
    }

    if (e.shiftKey && document.activeElement === firstElement) {
      lastElement.focus();
      e.preventDefault();
    }
    return undefined;
  };
  const keyListenersMap = new Map([[9, handleTabKey]]);
  useEffect(() => {
    function keyListener(e) {
      const listener = keyListenersMap.get(e.keyCode);
      return listener && listener(e);
    }
    document.addEventListener('keydown', keyListener);

    return () => document.removeEventListener('keydown', keyListener);
  });
  const {
    customClass = '',
    dialogTitle = '',
    showCloseButton = false,
    width = '',
    alignTop = false
  } = (children && children.props) || {};
  const lucetThemeClass =
    customProps && customProps.disableLucetTheme ? '' : 'lucet-brand-modal';
  return (
    <div
      style={{ display: 'block' }}
      id="dialog"
      className={
        customClass || (customProps && customProps.class)
          ? `${
              customClass || (customProps && customProps.class)
            } modal shown ${lucetThemeClass}`
          : `modal shown custom-modal ${lucetThemeClass}`
      }
      aria-describedby="dialog_desc"
      aria-labelledby="dialog_head"
      role="dialog"
      ref={modalRef}
    >
      <div
        className={clsx(
          style.modalDialogueWrapper,
          `modalDialogueWrapper align-items-${
            alignTop ? 'top' : 'center'
          } d-flex min-h-100 justify-content-center ${
            alignTop ? '' : 'padding-md'
          }`,
        )}
      >
        <div
          style={
            width || (customProps && customProps.width)
              ? { width: width || customProps.width }
              : {}
          }
          className={clsx(
            style.modalDialogueInner,
            style.modalDialogueInner2,
            'modal-dialog',
            lucetThemeClass,
            { 'modal-type1': !customClass },
          )}
        >
          <div className={`modal-content ${customProps && customProps.modalContentClass}`}>
            {(showCloseButton || (customProps && customProps.closeButton)) && (
              <button
                onClick={closeModal}
                type="button"
                className={style.closeButton}
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            )}
            {dialogTitle && (
              <div className="modal-header">
                <h4 id="dialog_head" className="modal-title vertically-center">
                  {dialogTitle}
                </h4>
              </div>
            )}
            <div className="modal-body">{children}</div>
            {footer && <div className="modal-footer">{footer}</div>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DesktopModal;

import QueryString from 'qs';
import { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  parseURL,
  filterURL as filterURLArrayFormatSQL,
} from '../helperFunctions';

function filterURL(prefix, value) {
  if (value === '') {
    return undefined;
  }
  return value;
}

const useFilterDataAsUrl = (
  path,
  arrayFormat = 'brackets',
  defaultDecoding = true,
) => {
  const history = useHistory();
  const location = useLocation();
  const handleSubmit = useCallback(
    (values) => {
      const currentParams = parseURL(location.search.slice(1), defaultDecoding);

      const query = QueryString.stringify(
        { ...currentParams, ...values },
        {
          filter: arrayFormat === 'SQL' ? filterURLArrayFormatSQL : filterURL,
          encode: false,
          arrayFormat: 'brackets',
        },
      );

      history.push(`?${query}`);
    },
    [history, location, arrayFormat],
  );
  const url = `${path || ''}${location.search.slice(1)}`;
  const params = useMemo(
    () => parseURL(location.search.slice(1), defaultDecoding),
    [location],
  );

  return [url, params, handleSubmit];
};

export default useFilterDataAsUrl;

import React from 'react';
import ReactTooltip from 'react-tooltip';

const AppTooltip = ({
  text,
  id,
  className,
  arrowColor,
  place,
  type,
  borderColor,
  delayShow = 0,
  delayHide = 0,
  delayUpdate = 0,
  getContent,
  effect,
  tooltipRef,
  overridePosition,
  afterShow,
  clickable = false,
  event,
  children,
  multiline,
}) => (
  <ReactTooltip
    id={id}
    place={place || 'bottom'}
    effect={effect || 'solid'}
    className={className || 'tooltip'}
    arrowColor={arrowColor || 'transparent'}
    type={type || 'dark'}
    borderColor={borderColor || 'transparent'}
    delayShow={delayShow}
    delayHide={delayHide}
    delayUpdate={delayUpdate}
    getContent={getContent}
    ref={tooltipRef}
    overridePosition={overridePosition}
    afterShow={afterShow}
    clickable={clickable}
    border
    event={event}
    globalEventOff={event}
    multiline={multiline}
  >
    {children || text}
  </ReactTooltip>
);

export default AppTooltip;

import React from 'react';
import DesktopModal from './DesktopModal';
import BottomSheetModal from './BottomSheetModal';
import { isMobile } from '../../helperFunctions';
import useWindowSize from '../../hooks/useWindowSize';

const isMobileScreen = isMobile();
const Modal = ({ children, closeModal, customProps }) => {
  const { windowWidth } = useWindowSize();
  return (
    <React.Fragment>
      {isMobileScreen || windowWidth < 768 ? (
        <BottomSheetModal closeModal={closeModal} customProps={customProps}>
          {children}
        </BottomSheetModal>
      ) : (
        <DesktopModal closeModal={closeModal} customProps={customProps}>
          {children}
        </DesktopModal>
      )}
    </React.Fragment>
  );
};
export default Modal;

import React from 'react';
import { UtilitiesProvider } from '../../../../context/utilities';
import FormWrapper from './FormWrapper';

const CommunicationPreferences = () => (
  <UtilitiesProvider>
    <FormWrapper />
  </UtilitiesProvider>
);

export default CommunicationPreferences;

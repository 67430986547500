import clsx from 'clsx';
import React from 'react';

const AlertDialogue = ({
  okText = 'OK',
  handleOk,
  children,
  cancelText,
  className = '',
}) => (
  <div
    id="dialog"
    role="alertdialog"
    aria-modal="true"
    aria-labelledby="dialog_head"
    aria-describedby="dialog_desc"
    className={clsx('alert-dialogue', className)}
  >
    <div id="dialog_desc" className="confirm-desc">
      {children}
    </div>
    <div
      style={{ justifyContent: 'center' }}
      className="footer-btn-wrap modal-footer padding-top-lg"
    >
      <button
        onClick={handleOk}
        className={`btn ${cancelText ? 'btn-default' : 'btn-primary'}`}
        type="button"
      >
        {cancelText || okText}
      </button>
    </div>
  </div>
);

export default AlertDialogue;

import React from 'react';
import Label from './Label';
import AppTooltip from '../shared/AppTooltip';

const checkBox = ({
  input,
  label,
  disabled,
  id,
  meta: { touched, error, warning },
  tooltip = {},
  customWrapperClass = '',
}) => (
  <div className={customWrapperClass}>
    <span
      data-tip=""
      data-for={`check-tooltip-${id}`}
      className={`checkbox-wrap checkbox-${id}`}
    >
      <input
        name={id}
        type="checkbox"
        id={id}
        checked={String(input.value) === 'true'}
        disabled={disabled}
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        {...input}
      />

      <Label {...{ input, label, htmlFor: id }} />
    </span>
    {tooltip && tooltip.body && (
      <AppTooltip
        id={`check-tooltip-${id}`}
        className={`check-tooltip-${id}`}
        arrowColor={tooltip.arrowColor || '#222'}
        type={tooltip.type || 'dark'}
        position={tooltip.position || 'bottom'}
      >
        {tooltip.body}
      </AppTooltip>
    )}
    {touched &&
      ((error && <span className="error">{error}</span>) ||
        (warning && <span>{warning}</span>))}
  </div>
);

export default checkBox;

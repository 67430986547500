const debounce = (callback, wait) => {
  let timeoutId = null;
  const debounced = (...args) => {
    window.clearTimeout(timeoutId);
    timeoutId = window.setTimeout(() => {
      callback(...args);
      timeoutId = null;
    }, wait);
  };

  debounced.cancel = () => {
    clearTimeout(timeoutId);
    timeoutId = null;
  };

  debounced.pending = () => timeoutId !== null;

  return debounced;
};

export default debounce;

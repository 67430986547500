import React, { useEffect } from 'react';
import Label from '../../../components/inputs/Label';
import wrapField from './wrapField';

const CheckBox = ({
  field,
  label,
  disabled,
  hideError,
  helpers: { setValue },
  className = 'checkbox-wrap',
  meta: { touched, error, warning },
  icon,
  defaultValue,
  onChange,
}) => {
  useEffect(() => {
    if (defaultValue != undefined) {
      setValue(String(defaultValue) == 'true');
    }
  }, [defaultValue]);
  return (
    <span>
      <span className={className}>
        <input
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...field}
          disabled={disabled}
          type="checkbox"
          id={`${field.name}_checkbox`}
          checked={String(field.value) === 'true'}
          onChange={(e) => {
            field.onChange(e);
            if(onChange) onChange(e.target.checked);
          }}
        />
        <Label
          {...{ input: field, label, icon, htmlFor: `${field.name}_checkbox` }}
          type="checkbox"
        />
      </span>

      {!hideError &&
        touched &&
        ((error && <span className="error">{error}</span>) ||
          (warning && <span>{warning}</span>))}
    </span>
  );
};

export default wrapField(CheckBox);

import React from "react"
const button = ({
  input,
  label,
  id,
  type,
  url,
  valueText,
  title,
  meta: { touched, error, warning }
}) => {
  const handleClick = () => {
    window.open(url, '_blank');
  };
  return (
  <div>
     <input type="button"
      className="form-control"
      id={id}
      value={valueText}
      title={title}
      onClick={handleClick}
      target='_blank'
    />
  </div>
)}


export default button
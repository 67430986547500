import React from 'react';

const FieldError = ({ error, touched, errorClass = '' }) => (
  <React.Fragment>
    {error && touched && (
      <div className={`field-error ${errorClass}`}>{error}</div>
    )}
  </React.Fragment>
);

export default FieldError;

import React from 'react';
import wrapField from './wrapField';
import Select from "react-select";
import {useField, getIn, Form, FormikProps, Formik} from 'formik';
import FieldError from './FieldError';

const SelectField = ({label, optionValueKey, optionLabelKey, ...props}) => {
  const {name, options, placeholder, customClass, showRequiredLabel} = props;
  const [field, meta, helpers] = useField(props);
  const {touched, error} = meta;
  return (
    <div className={`${error ? 'has-error' : ''} ${touched ? 'touched' : ''} field-wrapper-inner ${customClass || ''}`}>
      <fieldset>
        {label && (
          <legend className="prompt">
                  <span>{showRequiredLabel ? <span className="required-star">* </span> : null}
                    {label}</span>
          </legend>
        )}
        <div className="prompt">
          <select
            {...field}
            {...props}>

            {placeholder && (
              <option value="">{placeholder}</option>
            )}
            {options.map((option, index) => (
              <option key={index} value={optionValueKey ? option[optionValueKey] : option.value}>
                {optionLabelKey ? option[optionLabelKey] : option.label}
              </option>
            ))}

          </select>
          <FieldError {...{ error, touched }} />
        </div>
      </fieldset>
    </div>
  );
};

export default wrapField(SelectField);

import { isObjEmpty } from '../../helperFunctions';

export const setFieldsTouchedForErrors = (errors, setFieldTouched) => {
  Object.keys(errors).forEach((key) => {
    setFieldTouched(key, true, false);
  });
};

export const customSubmit = ({
  submit,
  setErrors,
  setFieldTouched,
  validateForm,
}) => {
  validateForm().then((errors) => {
    if (isObjEmpty(errors)) return submit();
    setErrors(errors);
    setFieldsTouchedForErrors(errors, setFieldTouched);
    return true;
  });
};

// @see https://formik.org/docs/api/formik#setfieldvalue-field-string-value-any-shouldvalidate-boolean--promisevoid--formikerrors
// Could expand to include setFieldTouched.
export const getSetValueFunction =
  (inputName, setFieldValue, { shouldValidate } = {}) =>
  (value) => {
    setFieldValue(inputName, value, shouldValidate);
  };

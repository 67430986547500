import React from 'react';
import Label from './Label';
import wrapperField from './wrapperField';

const textField = ({
  input,
  label,
  placeholder,
  type,
  filter,
  style,
  min,
  positiveOnly = false,
  max,
  disabled,
  hideError,
  elementRef,
  meta: { touched, error, warning },
}) => {
  const filterInput = filter || ((value) => /^-?\d{0,8}$/.test(value));
  const intsOnly = /^-?\d*\.?\d+$/;
  return (
    <div
      className={`component-wrapper has-touched-${touched} has-${
        error ? 'some' : 'no'
      }-errors`}
    >
      <Label {...{ input, label }} />
      <div className="main-field-cont">
        <input
          className="ip-text-block"
          placeholder={placeholder || 'Enter here'}
          type={type || 'text'}
          value={input.value}
          onBlur={input.onBlur}
          style={style}
          min={min}
          max={max}
          disabled={disabled}
          onWheel={(e) => e.target.blur()}
          onKeyDown={(e) => {
            let notAllowed = false;
            const isIntVal = intsOnly.test(e.key);
            // 37 (left arrow key), 39 (left arrow key), 8 (backspace key), 46 (delete key)
            const isAllowedKey = [8, 37, 39, 46].includes(e.keyCode);

            if (isIntVal === false && isAllowedKey === false) {
              notAllowed = e.key === '-' ? positiveOnly : true;
            }

            if (notAllowed) e.preventDefault();
          }}
          onChange={(e) => {
            const val = e.target.value;
            let triggerChange = true;
            if (val) {
              if (filterInput(val) === false || intsOnly.test(val) === false)
                triggerChange = false;
              if (triggerChange && min && val < min) triggerChange = false;
              if (triggerChange && max && val > max) triggerChange = false;
            }
            if (triggerChange) input.onChange(val);
          }}
          ref={elementRef}
        />
        {touched &&
          !hideError &&
          ((error && <span className="error">{error}</span>) ||
            (warning && <span>{warning}</span>))}
      </div>
    </div>
  );
};

export default wrapperField(textField);

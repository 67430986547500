import React from 'react';
import get from "lodash/get";
import {useField} from "formik";

export const formikAdapter = ({ field, form, ...props }) => ({
  ...props,
  name: field.name,
  input: {
    ...field,
    onBlur: (e) => {
      form.setTouched({...form.touched,[field.name]: true });
      form.handleBlur(field.name);
      if (props.onBlur) {
        props.onBlur(e);
      }
    },
    onChange: (e) => {
      form.setTouched({...form.touched,[field.name]: true });
      const value = e.target ? e.target.value : e;
      form.setFieldValue(field.name, value, true);
      if (props.onChange) {
        props.onChange(e);
      }
    },
  },
  meta: {
    touched: get(form.touched, field.name, false),
    dirty: form.dirty,
    error: get(form.errors, field.name, ""),
    valid: form.isValid,
    submitting: form.isSubmitting,
    pristine: !form.dirty,
  }
})
import React from 'react';
import { UtilitiesProvider } from '../../../../context/utilities';
import AccessMessageForm from './AccessMessageForm';

const AccessMessage = () => (
  <UtilitiesProvider>
    <AccessMessageForm />
  </UtilitiesProvider>
);

export default AccessMessage;

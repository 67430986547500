import React, { useRef } from 'react';
import ReactTooltip from 'react-tooltip';
import { CustomHtmlPrompt } from '../../../components/inputs';

const AdditionalInfo = ({ additionalInfo, moreText = 'More' }) => {
  const reactTooltipRef = useRef(null);
  return (
    <React.Fragment>
      {' '}
      <button
        data-tip
        data-event="click"
        data-for="react-tooltip-addi-info"
        className="info-btn"
        type="button"
        aria-describedby="react-tooltip-addi-info-content"
      >
        {moreText}
      </button>
      <ReactTooltip
        type="light"
        effect="solid"
        place="bottom"
        border
        globalEventOff="click"
        clickable
        id="react-tooltip-addi-info"
        aria-hidden="true"
        className="additional-info-box"
        ref={reactTooltipRef}
        overridePosition={(
          { left, top },
          currentEvent,
          currentTarget,
          node,
          place,
          desiredPlace,
          effect,
          offset,
        ) => {
          const rect = node.getBoundingClientRect();
          const screenWidth =
            window.innerWidth ||
            document.documentElement.clientWidth ||
            document.body.clientWidth;
          const screenHeight =
            window.innerHeight ||
            document.documentElement.clientHeight ||
            document.body.clientHeight;

          if (top < 10) {
            top = 10;
          } else if (top + rect.height > screenHeight) {
            top = screenHeight - rect.height - 10;
          }

          if (left < 10) {
            left = 10;
          } else if (left + rect.width > screenWidth) {
            left = screenWidth - rect.width - 10;
          }

          return { left, top };
        }}
      >
        <button
          type="button"
          className="close-tooltip fa fa-close"
          aria-label="close popup"
          onClick={() => {
            reactTooltipRef.current.tooltipRef = null;
            ReactTooltip.hide();
          }}
        />
        <div className="addi-info-content" id="react-tooltip-addi-info-content">
          <CustomHtmlPrompt label={additionalInfo} />
        </div>
      </ReactTooltip>
    </React.Fragment>
  );
};
export default AdditionalInfo;

import React from 'react';
import clsx from 'clsx';
import styles from './custom_messages.module.scss';
import MessageBody from './MessageBody';
import Base from './Base';
import { noop } from '../../helperFunctions';

const Message = ({
  type,
  locale,
  mode,
  metadata,
  message,
  isLoading,
  handleChangeLocale = noop,
}) => (
  <Base
    type={type}
    locale={locale}
    mode={mode}
    handleChangeLocale={handleChangeLocale}
  >
    <div className={clsx(styles.previewContainer)}>
      <MessageBody
        metadata={metadata}
        message={message}
        mode={mode}
        isLoading={isLoading}
      />
    </div>
  </Base>
);

export default Message;

import React from 'react';
import {Form, Formik} from "formik";
import {TextField} from "../../../components/inputs";
import {masks, validations, csrfHeader} from "../../../../helperFunctions";
import {formValidations} from "../../../helpers/moduleHelper";
import axios from 'axios';
import CustomHtmlPrompt from "../../../components/inputs/CustomHtmlPrompt";


class PermissionCheck extends React.Component {
  constructor(props) {
    super(props);

    this.state = {showNextButton: false}
  }

  handleSubmit = (values, url, setErrors) => {
    const {pageName, submitURL, setLoader} = this.props;
    setLoader(true);
    return axios({
      method: 'post',
      url: url + ".json",
      data: {"site_ipaddress": {name: values.name}},
      headers: csrfHeader,
    })
      .then(response => {
        if (response.data.errors) {
          this.throwSubmissionErrors(response.data.errors, setErrors);
          setLoader(false);
        } else if (response.status === 200 || response.status === 302) {
          window.location.href = response.request.responseURL;
        } else {
          setLoader(false);
        }
      })
      .catch(err => {
        setLoader(false);
        const msg =
          err.response && err.response.data && err.response.data.message
            ? err.response.data.message
            : err;
        alert(msg);
      });
  }

  throwSubmissionErrors = (errors, setErrors) => {
    if (errors) {
      let filteredErrors = {}
      Object.keys(errors).map((key, index) => {filteredErrors["name"] = errors[key].toString();})
      console.log(filteredErrors)
      setErrors(filteredErrors);
    }
  }

  validate = (values) => {
    const {locale} = this.props;
    const fieldErrorMessage = formValidations.fieldErrorMessage(locale);
    let errors = {};
    let nameErrors = !values.name ? fieldErrorMessage : formValidations.max(values.name, 100, locale);
    if (nameErrors) {
      errors.name = nameErrors;
    }
    this.setState({showNextButton: Object.keys(errors).length === 0})
    return errors;
  }

  handleBackButton = () => {
    const {backPath} = this.props;
    window.location.href = backPath ? backPath : "/welcome";
  }


  render() {
    const {pageName, errorMessage, submitURL, errorPresent, submitButtonLabel, tridiuumLogo} = this.props;
    const {showNextButton} = this.state;
    return (
      <div className="permission-check-inner">
        <div className="form-wrapper wrapper-section">
          <div className="symphony-logo-wrapper">
            <img src={tridiuumLogo ? `/assets/${tridiuumLogo.image}` : "/assets/polaris-landing-logo.png"} alt="Tridiuum Logo" title={tridiuumLogo.title} className={tridiuumLogo.class}/>
          </div>
          <div className="message-wrapper">
            <p><CustomHtmlPrompt label={errorMessage} /></p>
          </div>
          {errorPresent && (
            <div className={`rows`}>
              <div className="row clear">
                <div className="form-group form-footer">
                  <button onClick={this.handleBackButton} className={`btn btn-lg btn-primary btn-block`} name=" action" id="
                                                                     btn-next">{submitButtonLabel}
                  </button>
                </div>
              </div>
            </div>)}
        </div>
        {!errorPresent && (
          <Formik
            initialValues={{
              name: ''
            }}
            onSubmit={(values, {setSubmitting, setErrors}) => {
              setTimeout(() => {
                this.handleSubmit(values, submitURL, setErrors).then(r => {

                })
                setSubmitting(false);
              }, 400);
            }}
            validate={this.validate}
          >
            {props => (
              <Form className="form-wrapper permission-check-cont">
                <div className={`rows`}>
                  <div className="row clear">
                    <div className="form-group">
                      <TextField
                        name="name"
                        type="text"
                        placeholder="IP Name"
                        max={100}
                        inputClass={`form-control`}
                      />
                    </div>
                  </div>
                  <div className="row clear">
                    <div className="form-group form-footer">
                      <button type="submit" disabled={!showNextButton}  className={`btn btn-lg btn-primary btn-block`} name=" action" id="
                                                            btn-next">{submitButtonLabel}
                      </button>
                    </div>
                  </div>
                </div>

              </Form>
            )}
          </Formik>
        )}
      </div>

    );
  }
}

export default PermissionCheck;

import axios from 'axios';

const getNotificationPreferences = (patientID) =>
  axios.get(
    `/open_filters/patient_notification_preference?patient_id=${patientID}`,
  );

const updateNotificationPreferences = (data) => {
  console.log('data', data)
  return axios.post(`/update_communication_preferences`, data);
}

// eslint-disable-next-line import/prefer-default-export
export { getNotificationPreferences, updateNotificationPreferences };

import React from 'react';
import Label from './Label';

const textFieldInline = ({
  input,
  label,
  type = 'text',
  placeholder,
  disabled,
  style,
  showDummy,
  className,
  containerClass,
  hideError,
  onKeyDown,
  labelClass,
  meta: { touched, error, warning },
}) => (
  <React.Fragment>
    <Label {...{ input, label, labelClass }} />
    <span className={`${containerClass || ''}`}>
      {!showDummy ? (
        <input
          {...input}
          placeholder={placeholder || 'Enter here'}
          type={type}
          disabled={disabled}
          className={`ip-text ${className || ''} ${
            touched && error ? 'ip-has-error' : ''
          }`}
          style={style}
          onKeyDown={onKeyDown}
        />
      ) : (
        <input
          value=""
          placeholder={placeholder || 'Enter here'}
          type={type}
          disabled={disabled}
          className={`ip-text ${className || ''} ${
            touched && error ? 'ip-has-error' : ''
          }`}
          style={style}
          onKeyDown={onKeyDown}
        />
      )}

      {!hideError &&
        touched &&
        ((error && <span className="error">{error}</span>) ||
          (warning && <span>{warning}</span>))}
    </span>
  </React.Fragment>
);

export default textFieldInline;

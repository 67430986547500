import React from 'react';
import { UtilitiesContext, defaultLoaderKey } from '.';

const useLoader = ({ key = defaultLoaderKey, displayMessage = null } = {}) => {
  const context = React.useContext(UtilitiesContext);
  if (context === undefined) {
    throw new Error('useUtilities must be used within a UtilitiesProvider');
  }
  const { showSpinner, hideSpinner } = context;

  const showLoader = ({ displayMessage: message = null } = {}) => {
    showSpinner({
      key,
      displayMessage: message || displayMessage,
    });
  };

  const hideLoader = () => {
    hideSpinner({
      key,
    });
  };

  const setLoader = (show, { displayMessage: message = null } = {}) => {
    if (show) {
      showLoader({ displayMessage: message || displayMessage });
    } else {
      hideLoader();
    }
  };

  return { showLoader, hideLoader, setLoader };
};

export default useLoader;

import React from 'react';
import clsx from 'clsx';
import { resizeImage } from '../../helperFunctions';
import wrapperField from './wrapperField';

class fileInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      imageFile: '',
    };
    this.fileRef = React.createRef();
  }

  handleChange = (event, input) => {
    const { fileTypes = ['image/jpeg', 'image/jpg', 'image/png'] } = this.props;
    event.preventDefault();
    const imageFile = event.target.files[0];
    if (imageFile) {
      const localImageUrl = URL.createObjectURL(imageFile);
      const imageObject = new window.Image();

      imageObject.onload = async function onImageLoad() {
        const maxWidth = 1500;
        const maxHeight = 1500;
        const imageWidth = imageObject.width;
        const imageHeight = imageObject.height;
        if (
          imageWidth > maxWidth ||
          imageHeight > maxHeight ||
          imageFile.size > 1024 * 1024 // 1MB
        ) {
          const resizedFile = await resizeImage(imageObject, {
            fileType: fileTypes.includes(imageFile.type)
              ? imageFile.type
              : fileTypes[0],
          });
          input.onChange(resizedFile);
        } else {
          imageFile.width = imageObject.naturalWidth;
          imageFile.height = imageObject.naturalHeight;
          input.onChange(imageFile);
        }

        URL.revokeObjectURL(imageFile);
      };

      imageObject.src = localImageUrl;
      this.setState({ imageFile: URL.createObjectURL(event.target.files[0]) });
    }
  };

  handleDelete = (event, input) => {
    this.setState({ imageFile: '' });
    this.props.input.onChange('');
    this.fileRef.current.value = '';
  };

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.imageFile != this.props.imageFile) {
      this.setState({ imageFile: nextProps.imageFile });
    }
  }

  componentWillMount() {
    this.setState({ imageFile: this.props.imageFile });
  }

  render() {
    const {
      handleOnDrop,
      deleteImage,
      label,
      forLabel,
      infoText,
      input,
      disabled,
      imageFile,
      maxSize,
      minSize,
      disableFileUpload,
      iconLabel = 'Upload image',
      rightAccessory,
      meta: { touched, error, pristine },
    } = this.props;
    return (
      <div>
        <label
          id={`field-label-${input.name.replace(/[^a-zA-Z0-9_]/g, '_')}`}
          className="control-label"
          htmlFor=""
          style={{ width: '100%' }}
        >
          <span
            className="file-label"
            dangerouslySetInnerHTML={{ __html: label }}
          />
          <span className="info-size">{infoText}</span>
          {rightAccessory && (
            <span style={{ float: 'right' }}>{rightAccessory}</span>
          )}
        </label>
        <div
          className={`image-upload-preview ${disabled ? 'permitted_none' : ''}`}
        >
          {this.state.imageFile ? (
            <div className="avatar-preview">
              <div
                id={`preview_${forLabel}`}
                className="logo-preview"
                style={{ backgroundImage: `url(${this.state.imageFile})` }}
              />
              <div className="avatar-buttons">
                <div>
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label
                    className={clsx('cursor-hand margin-bottom-sm', {
                      disabled: disableFileUpload,
                    })}
                    htmlFor={forLabel}
                  >
                    Upload New
                  </label>
                </div>
                <div>
                  <button
                    className={clsx('btn-plain', {
                      disabled: disableFileUpload,
                    })}
                    onClick={this.handleDelete}
                    type="button"
                    disabled={disableFileUpload}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className="avatar-upload">
              <div className="avatar-edit">
                <label className="cursor-hand" htmlFor={forLabel}>
                  <i className="glyphicon glyphicon-plus-sign" />
                  {iconLabel}
                </label>
              </div>
            </div>
          )}
          <input
            className="input-element"
            type="file"
            id={forLabel}
            ref={this.fileRef}
            onChange={(event) => this.handleChange(event, input)}
            name={forLabel}
            accept=".png, .jpg, .jpeg"
            disabled={disableFileUpload}
          />
        </div>
        {error && <span className="error">{error}</span>}
      </div>
    );
  }
}

export default wrapperField(fileInput);

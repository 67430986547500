import React from 'react';
import CustomHtmlPrompt from './CustomHtmlPrompt';
import AdditionalInfo from '../../survey/patient_view/components/AdditionalInfo';

const Prompt = (props) => {
  const {
    label,
    showRequiredLabel,
    additionalInfo,
    moreText,
    overrideClass,
    id,
    component,
  } = props;
  const CustomTag = component || 'legend';
  return (
    <React.Fragment>
      {label && (
        <React.Fragment>
          <CustomTag className={overrideClass || 'prompt'} id={id}>
            {showRequiredLabel && <sup>*</sup>}
            <CustomHtmlPrompt label={label} />
          </CustomTag>
          {additionalInfo && (
            <AdditionalInfo
              moreText={moreText}
              additionalInfo={additionalInfo}
            />
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default Prompt;

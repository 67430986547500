import React from 'react';
import { components } from 'react-select';
import { createOptionID } from '../../helperFunctions';

export function customSelectOptionWithProvidedComponent(
  Option = components.Option,
) {
  return function CustomSelectOption({ children, ...props }) {
    // eslint-disable-next-line no-unused-vars
    const { label = '', data } = props;
    return (
      <div className={createOptionID(label)}>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Option {...props}>
          {children}
          {/* Show right hand accessory text here */}
          {data.accessoryViewText && (
            <i className="accessory-view-text">{data.accessoryViewText}</i>
          )}
        </Option>
      </div>
    );
  };
}

export default customSelectOptionWithProvidedComponent();

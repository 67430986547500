import React from 'react';
import axios from 'axios';
import {
  AsyncTypeahead,
  TypeaheadMenu,
  Menu,
  MenuItem,
} from 'react-bootstrap-typeahead';
import Label from './Label';

const groupBy = (array, key, optGroups = []) =>
  // Return the end result
  array.reduce((result, currentValue) => {
    if (result.has(currentValue[key])) {
      result.get(currentValue[key]).push(currentValue);
    } else {
      result.set(currentValue[key], [currentValue]);
    }
    return result;
  }, new Map([...optGroups.map((o) => [o, []])]));
class typeAhead extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      options: props.defaultSelected ? props.defaultSelected : [],
    };
    if (props.defaultSelected) {
      props.input.onChange(
        props.getValue
          ? props.getValue(props.defaultSelected)
          : props.defaultSelected[0]
          ? props.defaultSelected[0].id
          : '',
      );
    }
  }

  focus = () => {
    this._typeahead.focus();
  };

  componentWillUpdate(nextProps) {
    const { defaultSelected } = this.props;
    if (
      nextProps.showSearchText &&
      nextProps.defaultSelected &&
      JSON.stringify(nextProps.defaultSelected) !==
        JSON.stringify(defaultSelected)
    ) {
      this.setState({ options: nextProps.defaultSelected });
    }
  }

  render() {
    const {
      input,
      label,
      showRequiredLabel,
      placeholder,
      labelKey,
      filterBy = () => true,
      defaultInputValue,
      defaultSelected,
      disabled,
      getValue,
      searchText,
      inputProps,
      includeHeaders,
      isUnControlled,
      optGroups,
      locationSearch,
      onSelection,
      delay = 0,
      meta: { touched, error, warning },
      customWrapperClass = '',
    } = this.props;

    const { options, searchParam, isLoading } = this.state;
    return (
      <div>
        <Label {...{ input, label, showRequiredLabel }} />
        <div className={`position-relative ${customWrapperClass}`}>
          <AsyncTypeahead
            {...this.state}
            {...input}
            id={input.name}
            shouldSelect
            labelKey={labelKey}
            onFocus={() => {
              if (!input.value) this.setState({ searchParam: true });
            }}
            minLength={2}
            delay={delay}
            onSearch={this._handleSearch}
            onInputChange={this._handleInputChange}
            placeholder={placeholder || 'Start typing...'}
            renderMenu={(results, menuProps, state) => {
              // Hide the menu when there are no results.
              if (!results.length && isLoading) {
                return (
                  <Menu {...menuProps}>
                    <MenuItem disabled option={{}}>
                      {searchText || 'Searching...'}
                    </MenuItem>
                  </Menu>
                );
              }
              if (!results.length) {
                if (locationSearch) return '';
                return (
                  <Menu {...menuProps}>
                    <MenuItem disabled option={{}}>
                      no results match “{state.text}”
                    </MenuItem>
                  </Menu>
                );
              }
              if (includeHeaders === true) {
                let index = 0;
                const groupsAndPatients = groupBy(results, 'type', optGroups);
                const items = Array.from(groupsAndPatients, ([key]) => key).map(
                  (groupORPatient) => (
                    <React.Fragment key={groupORPatient}>
                      {index !== 0 && <Menu.Divider />}
                      <Menu.Header>{groupORPatient}</Menu.Header>
                      {groupsAndPatients.get(groupORPatient).map((i) => {
                        const item = (
                          <MenuItem key={index} option={i} position={index}>
                            {i.name}
                          </MenuItem>
                        );
                        index += 1;
                        return item;
                      })}
                    </React.Fragment>
                  ),
                );

                return <Menu {...menuProps}>{items}</Menu>;
              }
              return React.createElement(TypeaheadMenu, {
                ...menuProps,
                labelKey: state.labelKey,
                options: results,
                text: state.text,
              });
            }}
            ref={(typeahead) => (this._typeahead = typeahead)}
            onBlur={() => {
              if (
                (!input.value && this._typeahead && !locationSearch) ||
                (locationSearch && this._typeahead.props.selected.length === 0)
              ) {
                this._typeahead.clear();
              }
              this.setState({ searchParam: false });
            }}
            filterBy={filterBy}
            defaultInputValue={defaultInputValue}
            disabled={disabled}
            defaultSelected={defaultSelected || []}
            // value={options.find(option => option.id === input.value) || null}
            selected={
              isUnControlled === true
                ? null
                : typeof input.value === 'string'
                ? options.filter((option) =>
                    locationSearch
                      ? option.displayString === input.value
                      : option.id === input.value,
                  )
                : options.filter((option) => option.id === input.value.id)
            }
            onChange={(value) => {
              input.onChange(
                getValue ? getValue(value) : value[0] ? value[0].id : '',
              );
              if (onSelection) onSelection(value);
            }}
            useCache={false}
            inputProps={inputProps}
            align="left"
            selectHintOnEnter
            highlightOnlyResult
          />

          {touched &&
            ((error && <span className="error">{error}</span>) ||
              (warning && <span>{warning}</span>))}
          {searchParam && (
            <div
              className="rbt-menu-min dropdown-menu show"
              style={{ padding: '10px' }}
            >
              <span> Please enter 2 or more characters</span>
            </div>
          )}
        </div>
      </div>
    );
  }

  _handleSearch = (query) => {
    const { url, getOptions } = this.props;
    const trimmedQuery = query.trim();
    if (trimmedQuery.length < 2) {
      return;
    }
    this.setState({ isLoading: true });
    axios
      .get(`${url}${encodeURIComponent(trimmedQuery)}`)
      .then((r) => {
        this.setState({
          isLoading: false,
          options: getOptions ? getOptions(r) : (r.data && r.data.data) || [],
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  _handleInputChange = (val) => {
    const { locationSearch } = this.props;
    if (locationSearch && this._typeahead.props.selected.length) {
      this._typeahead.clear();
    }
    this.setState({ searchParam: val.length < 2 });
  };
}

export default typeAhead;

import React from 'react';
import Select from 'react-select';
import CustomSelectOption from './CustomSelectOption';
import Label from './Label';

const selectBox = ({
  input,
  label,
  options,
  placeholder,
  isOptionDisabled,
  showRequiredLabel,
  minSelected,
  valueType,
  disabled,
  maxLength,
  styles,
  onKeyDown,
  lucetTheme = false,
  meta: { touched, error, warning },
}) => {
  const min = minSelected || [];
  const value = Array.isArray(input.value)
    ? input.value.map((val) => options.find((option) => option.value === val))
    : input.value
        .split(',')
        .map((val) => options.find((option) => option.value === val));
  return (
    <div>
      <Label {...{ input, label, showRequiredLabel }} />
      <div>
        <Select
          {...input}
          value={value}
          // onChange={(value) => input.onChange(value.map(e => e.value).join(","))}
          onChange={(value, meta) => {
            if (
              meta.action == 'select-option' &&
              meta.option.is_none_of_the_above
            ) {
              value = [meta.option];
            } else {
              value = value.filter((o) => !o.is_none_of_the_above);
            }
            const newVal = [...new Set([...min, ...value.map((e) => e.value)])];
            input.onChange(valueType == 'array' ? newVal : newVal.join(','));
          }}
          blurInputOnSelect={false}
          onBlur={() => input.onBlur()}
          onKeyDown={onKeyDown}
          options={
            maxLength != undefined && value.length >= maxLength ? [] : options
          }
          noOptionsMessage={() =>
            maxLength != undefined && value.length >= maxLength
              ? "You've reached the max number of selections."
              : 'No options available'
          }
          placeholder={placeholder || 'Select options'}
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          className="react-select"
          isMulti
          isOptionDisabled={isOptionDisabled}
          isClearable={false}
          isDisabled={disabled}
          // components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
          theme={(theme) => ({
            ...theme,
            borderRadius: 0,
            colors: {
              ...theme.colors,
              primary: lucetTheme ? '#851c55' : '#69ad24',
            },
          })}
          classNamePrefix="custom-react-select"
          styles={styles}
          components={{ Option: CustomSelectOption }}
        />

        {touched &&
          ((error && <span className="error">{error}</span>) ||
            (warning && <span>{warning}</span>))}
      </div>
    </div>
  );
};

export default selectBox;

import React from 'react';

const AuthenticationContext = React.createContext();

const AuthenticationProvider = (props) => (
  <AuthenticationContext.Provider value={{ token: props.token }} {...props}>
    {props.children}
  </AuthenticationContext.Provider>
);

function withAuthenticationProvider(WrappedComponent) {
  return (props) => {
    return (
      <AuthenticationProvider {...props}>
        <WrappedComponent {...props} />
      </AuthenticationProvider>
    );
  };
}

const useAuthentication = () => {
  const context = React.useContext(AuthenticationContext);
  if (context === undefined) {
    throw new Error(
      'useAuthentication must be used within a AuthenticationProvider',
    );
  }
  return context;
};

const AuthenticationConsumer = AuthenticationContext.Consumer;

export {
  AuthenticationContext,
  AuthenticationProvider,
  useAuthentication,
  AuthenticationConsumer,
  withAuthenticationProvider,
};

import React from 'react';
import SecuredMessageForm from "./SecuredMessageForm";
import './style.css.scss'

class Forms extends React.Component {

  constructor(props) {
    super(props);

    this.state = {modal: {}}

  }

  goToSubmitPage = () => {
    const {submitURL} = this.props;
    if (submitURL) {
      window.location.href = submitURL
    }
  }
  
  openModal = (name) => {
    document.body.classList.add("modal-open");
    this.setState({
      modal: Object.assign({}, this.state.modal, {
        [name]: true,
      })
    });
  };

  closeModal = (name) => {
    document.body.className = document.body.className.replace(
      'modal-open',
      ''
    );
    this.setState({
      modal: Object.assign({}, this.state.modal, {
        [name]: false
      }),
    });
  };

  render() {
    const {pageName} = this.props;
    const {modal} = this.state;
    return (
      <div>
        {pageName === 'send_code' && (
          <SecuredMessageForm {...this.props} />
        )}

        {modal.DialogModal && (
          <DialogModal
            {...this.props}
            closeModal={() => {
              this.closeModal('DialogModal');
              this.goToSubmitPage();
            }}
          />
        )}
      </div>
    );
  }
}

export default Forms;

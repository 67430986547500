import React from 'react';
import Label from './Label';
import wrapField from './wrapField';

const textField = ({
  input,
  label,
  placeholder,
  elementRef,
  disabled,
  meta: { touched, error, warning },
}) => (
  <div>
    <Label {...{ input, label }} />
    <div>
      <input
        className="ip-text-block"
        placeholder={placeholder || 'Enter here'}
        type="text"
        value={input.value}
        onBlur={input.onBlur}
        disabled={disabled}
        onChange={(e) => {
          const val = e.target.value;
          const x = val.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
          const newVal = !x[2]
            ? x[1]
            : `(${x[1]}) ${x[2]}${x[3] ? `-${x[3]}` : ''}`;
          input.onChange(newVal);
        }}
        ref={elementRef}
      />
      {touched &&
        ((error && <span className="error">{error}</span>) ||
          (warning && <span>{warning}</span>))}
    </div>
  </div>
);

const wrappedPhoneField = wrapField(textField);
export default textField;
export { wrappedPhoneField };

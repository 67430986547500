import React from 'react';
import wrapField from './wrapField';
import Label from './Label';
import { retainCursorPositionOnChange } from '../../helperFunctions';

const textField = ({
  input,
  label,
  type,
  placeholder = 'Enter here',
  showRequiredLabel,
  labelClass,
  fieldClass,
  disabled,
  readOnly,
  maxLength,
  min,
  meta: { touched, error, warning },
  textOnly,
  elementRef,
  rightAccessory,
}) => (
  <div>
    {textOnly ? (
      <React.Fragment>
        <Label {...{ input, label, labelClass }} />
        <p
          className={`padding-bottom-sm ${input.value ? '' : 'no-info-text'}`}
          style={{ minHeight: '2.7em' }}
        >
          {input.value || 'No information provided'}
        </p>
      </React.Fragment>
    ) : (
      <React.Fragment>
        <div className="space-between">
          <Label {...{ input, label, showRequiredLabel, labelClass }} />
          {rightAccessory && (
            <span style={{ float: 'right' }}>{rightAccessory}</span>
          )}
        </div>
        <div>
          <input
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...input}
            className={fieldClass || 'ip-text-block'}
            placeholder={placeholder}
            type={type || 'text'}
            disabled={disabled}
            readOnly={readOnly}
            maxLength={maxLength}
            min={min}
            ref={elementRef}
            onChange={(event) => {
              input.onChange(event);
              retainCursorPositionOnChange(event, input);
            }}
          />
          {touched &&
            ((error && <span className="error">{error}</span>) ||
              (warning && <span>{warning}</span>))}
        </div>
      </React.Fragment>
    )}
  </div>
);

export default wrapField(textField);

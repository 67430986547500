import React from 'react';

class SingleCheckBox extends React.Component {
  render() {
    const { label, onChange, value, id } = this.props;

    return (
      <div>
        <span className="checkbox-wrap">
          <input
            onChange={(e) => {
              onChange(e.target.checked, id);
            }}
            value={id}
            type="checkbox"
            id={id}
            checked={String(value) == 'true'}
          />
          <label htmlFor={id}>
            {label}
          </label>
        </span>
      </div>
    );
  }
}

export default SingleCheckBox;

import React from 'react';
import CustomHtmlPrompt from './CustomHtmlPrompt';
import Prompt from './Prompt';

const Message = (props) => {
  const { label, additionalInfo, moreText = 'more', warningText } = props;
  return (
    <div>
      <Prompt {...{ label, additionalInfo, moreText }} component="h2" />
      {warningText && (
        <h2 className="prompt">
          <CustomHtmlPrompt label={warningText} />
        </h2>
      )}
    </div>
  );
};

export default Message;

import React from 'react';
import { BottomSheet } from 'react-spring-bottom-sheet';

const BottomSheetModal = ({ children, closeModal, customProps }) => {
  const lucetThemeClass =
    customProps && customProps.disableLucetTheme ? '' : 'lucet-brand-modal';
  return (
    <BottomSheet
      open
      onDismiss={closeModal}
      expandOnContentDrag
      className={`bottom-sheet-cont ${
        customProps && customProps.class
      } ${lucetThemeClass}`}
      initialFocusRef={false}
    >
      {children}
    </BottomSheet>
  );
};
export default BottomSheetModal;

import React, { useEffect } from 'react';
import Slider from 'react-rangeslider';
import 'react-rangeslider/lib/index.css';
import Label from './Label';

const selectBox = ({
  inputData,
  input,
  label,
  meta: { touched, error, warning },
  sliderLabels,
  customWrapperClass = '',
  // eslint-disable-next-line arrow-body-style
}) => {
  return (
    <div>
      <Label {...{ input, label }} />
      <div>
        <div className={`slider-wrap ${customWrapperClass}`}>
          <Slider
            min={Number(inputData.slider_min) || 0}
            max={Number(inputData.slider_max) || 0}
            value={
              (sliderLabels
                ? Object.values(sliderLabels).indexOf(input.value.toString())
                : input.value) || 0
            }
            onChange={(value) => {
              input.onChange(sliderLabels ? sliderLabels[value] : value);
            }}
            labels={sliderLabels}
            format={() => input.value}
          />
          <span className="slider-value">{input.value}</span>
        </div>

        {touched &&
          ((error && <span className="error">{error}</span>) ||
            (warning && <span>{warning}</span>))}
      </div>
    </div>
  );
};

export default selectBox;

import React from 'react';
import Label from './Label';

class WeekDaysSelect extends React.Component {
  constructor(props) {
    super(props);
    const value = props.input.value || [];
    const options = props.options ? props.options : [];

    this.state = {
      options: options.map((o) => ({ ...o, checked: value.includes(o.value) })),
      value: props.value ? props.value : [],
    };
  }

  componentDidUpdate(prevProps) {
    const { props } = this;
    if (JSON.stringify(prevProps.value) !== JSON.stringify(this.props.value)) {
      const value = props.value || [];
      const options = props.options || [];
      this.setState({
        options: options.map((o) => ({
          ...o,
          checked: value.includes(o.value),
        })),
        value: props.value || [],
      });
    }
  }

  render() {
    const {
      label,
      showRequiredLabel,
      minSelected,
      input,
      meta: { touched, error, warning },
    } = this.props;
    const { options } = this.state;

    return (
      <div>
        <Label {...{ input, label, showRequiredLabel }} />
        <div className="btn-round-wrap">
          {options.map((item, i) => (
            <span key={i} className="btn-round">
              <input
                onChange={this.handleCheckChild}
                value={item.value}
                type="checkbox"
                id={item.value}
                checked={String(item.checked) == 'true'}
                disabled={minSelected.includes(item.value)}
              />
              <label htmlFor={item.value}>{item.label}</label>
            </span>
          ))}
        </div>
        {touched &&
          ((error && <div className="error">{error}</div>) ||
            (warning && <div>{warning}</div>))}
      </div>
    );
  }

  handleCheckChild = (event) => {
    const { onChange } = this.props.input;
    const { options } = this.state;
    options.forEach((option) => {
      if (option.value === event.target.value)
        option.checked = event.target.checked;
    });
    onChange(options.filter((k) => k.checked).map((o) => o.value));
  };
}

export default WeekDaysSelect;

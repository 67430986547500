import React, { useEffect, useMemo, useState } from 'react';
import { Form, Formik } from 'formik';
import clsx from 'clsx';
import axios from 'axios';
import LanguageSelector, {
  defaultSupportedLanguages,
} from '../../../../components/UI/LanguageSelector';
import styles from './access_message.module.scss';
import { csrfHeader, validations } from '../../../../helperFunctions';
import { TextField } from '../../../components/inputs';
import {
  DateInputFieldFormik,
  maxDateCurrentDate,
  minDateDefault,
} from '../../../../components/inputs/DateInputField';
import useFilterDataAsUrl from '../../../../hooks/useFilterDataAsUrl';
import useLoader from '../../../../context/utilities/useLoader';
import LucetLogo from '../../../../components/UI/LucetLogo';
import { LanguageTranslator } from '../../../../LanguageTranslator';

const AccessMessageForm = () => {
  const [data, setData] = useState({});
  const [errorText, setErrorText] = useState();

  const [, params] = useFilterDataAsUrl();
  const {
    id: securedMessageID,
    patient_id: patientID,
    translations,
    locale,
    post_validation_url: postValidationURL,
    access_attempts_exceed: attemptsExceeded,
  } = data || {};
  const { setLoader } = useLoader();

  const translated = useMemo(
    () => LanguageTranslator.getTranslations(locale),
    [locale],
  );

  const loadData = (newLocale = null) => {
    setLoader(true);
    const queryParams = params || {};
    if (newLocale) {
      queryParams.locale = newLocale;
    }
    axios.get(`/access_message.json`, { params }).then((r) => {
      if (r && r.data && r.data.id) {
        setData(r.data);
      }
      setLoader(false);
    });
  };

  const validate = (values) => {
    const requiredValidation = validations.required;
    const dobValidation = validations.dob;
    const errors = {};
    if (requiredValidation(values.access_code)) {
      errors.access_code = requiredValidation(values.access_code, locale);
    }
    if (requiredValidation(values.dob)) {
      errors.dob = requiredValidation(values.dob, locale);
    }
    if (dobValidation(values.dob)) {
      errors.dob = dobValidation(values.dob, locale);
    }
    return errors;
  };

  const handleSubmit = (values, { setSubmitting }) => {
    if (attemptsExceeded) return;
    const { dob, access_code } = values || {};
    setLoader(true);
    setErrorText(null);
    axios
      .post(
        `/validate_access_code.json`,
        {
          dob,
          access_code,
          secured_message_id: securedMessageID,
          patient_id: patientID,
          locale,
        },
        {
          headers: csrfHeader,
        },
      )
      .then((r) => {
        const result = r && r.data;
        setSubmitting(false);
        if (result) {
          if (result.success) {
            window.location.href = postValidationURL;
          } else {
            setLoader(false);
            if (result.access_attempts_exceed) {
              loadData();
            } else if (result.error) {
              setErrorText(result.error);
            }
          }
        }
      });
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <div className={styles.accessCodeContainer} role="main">
      <div className={styles.accessCodeSection}>
        <LucetLogo type="secure_message" logoType="plum" showCaption={false} />
        {data && Object.keys(data).length > 0 && (
          <Formik
            initialValues={{
              access_code: '',
              dob: '',
            }}
            onSubmit={handleSubmit}
            enableReinitialize
            validateOnMount
            validate={validate}
          >
            {({ isSubmitting, isValid }) => (
              <Form
                className={styles.formWrapper}
                aria-label={translated.authentication_form}
                role="form"
              >
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <p className={styles.label}>
                  {translations.secured_message_prefered_delivery_method_text}
                </p>
                <label htmlFor="access_code" className={styles.label}>
                  {translations.secured_message_enter_access_code}
                </label>

                <div className={styles.codeWrapper}>
                  <TextField
                    name="access_code"
                    type="text"
                    customClass={styles.codeField}
                    showError={false}
                    disabled={attemptsExceeded}
                    autoComplete="one-time-code"
                  />
                </div>
                <label htmlFor="dob" className={styles.label}>
                  {translations.secured_message_enter_dob}
                </label>
                <div className={`${styles.dobWrapper}`}>
                  <DateInputFieldFormik
                    name="dob"
                    id="dob"
                    areaLabel={`${translations.secured_message_enter_dob}, MM/DD/YYYY`}
                    placeholder="MM/DD/YYYY"
                    maxDate={maxDateCurrentDate}
                    minDate={minDateDefault}
                    showRequiredLabel={false}
                    disabled={attemptsExceeded}
                    hideError
                    autoComplete="bday"
                  />
                </div>
                <div className={styles.buttonWrapper}>
                  <button
                    type="submit"
                    className={`btn btn-primary ${styles.submitButton}`}
                    disabled={isSubmitting || !isValid}
                    tabIndex="0"
                  >
                    {translations.access_message}
                  </button>
                </div>
                {errorText && (
                  <div className={styles.errorWrapper} role="alert">
                    <img
                      src="/assets/exclamation-circle.png"
                      alt="Error Message"
                      title="Error Message"
                      width={25}
                      aria-hidden="true"
                    />
                    {/* eslint-disable-next-line jsx-a11y/heading-has-content,jsx-a11y/label-has-associated-control */}
                    <label className={clsx(styles.label, styles.errorText)}>
                      {errorText}
                    </label>
                  </div>
                )}
              </Form>
            )}
          </Formik>
        )}

        {data && Object.keys(data).length > 0 && (
          <div className={`language-selector ${styles.languageSelector}`}>
            <LanguageSelector
              currentLocale={locale}
              supportedLanguages={defaultSupportedLanguages}
              wraperClasses="language-selector-wrap"
              areaLabel={translated.change_language}
              onChange={(newLocale) => {
                loadData(newLocale);
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default AccessMessageForm;

import React from 'react';
import wrapField from './wrapField';
import Prompt from './Prompt';
import FieldError from './FieldError';

const Scale = (props) => {
  const {
    field,
    meta: { error, touched, value },
    label,
    scaleLowLabel,
    scaleMidLabel,
    scaleHighLabel,
    disabled,
    additionalInfo,
    moreText,
    allowNoResponse,
    allowNoResponseLabel,
    allowNoResponseValue,
  } = props;

  return (
    <fieldset className="user-option-box user-option-box-scroll scale-option-box">
      <Prompt {...{ label, additionalInfo, moreText }} />
      <div className="scale-outer">
        <div className="scale-titles">
          <div className="left">{scaleLowLabel}</div>
          <div className="middle">{scaleMidLabel}</div>
          <div className="right">{scaleHighLabel}</div>
        </div>
        <ul className="rating-scale">
          {[...Array(11)].map((o, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <li key={i}>
              <input
                {...field}
                name={field.name}
                type="radio"
                value={i}
                // eslint-disable-next-line eqeqeq
                checked={String(i) == value}
                disabled={disabled}
                id={`${field.name}_option_${i}`}
              />
              <label htmlFor={`${field.name}_option_${i}`}>{i}</label>
            </li>
          ))}
        </ul>
      </div>
      {allowNoResponse && (
        <div style={{ marginTop: 20 }} className="user-option-box option-tab">
          <input
            {...field}
            type="radio"
            name={field.name}
            value={allowNoResponseValue}
            // eslint-disable-next-line eqeqeq
            checked={String(allowNoResponseValue) == value}
            id={`${field.name}_no_response`}
            disabled={disabled}
          />
          <label htmlFor={`${field.name}_no_response`}>
            {allowNoResponseLabel}
          </label>
        </div>
      )}
      <FieldError {...{ error, touched }} />
    </fieldset>
  );
};

export default wrapField(Scale);

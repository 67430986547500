import React from 'react';
import { formikAdapter } from './formikAdapter';

export default function wrapField(WrappedComponent, selectData) {
  return (props) => {
    let { input, customClass } = props; // Redux-Form (default)
    const { field } = props;
    if (input == null) {
      // Formik
      return (
        <div className={`ip-field-wrapper-${field.name} ${customClass}`}>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <WrappedComponent {...props} {...formikAdapter(props)} />
        </div>
      );
    } else {
      // Redux
      const {
        customClass,
        meta: { touched, error, warning },
      } = props;
      return (
        <div
          className={`ip-field-wrapper-${input.name} ${customClass} ${
            error ? 'has-error' : ''
          } ${touched ? 'touched' : ''} 
            field-wrapper-inner ${customClass || ''}`}
        >
          <WrappedComponent {...props} />
        </div>
      );
    }
  };
}

import React from 'react';
import wrapField from './wrapField';
import FieldError from './FieldError';

const RadioGroupSimple = ({
  field,
  meta,
  label,
  options,
  disabled,
  showRequiredLabel,
  customClass,
  onChange,
}) => {
  const { touched, error } = meta;
  return (
    <fieldset className={`checkbox-group ${customClass}`}>
      {label && (
        <legend className="label-block">
          {showRequiredLabel ? <span className="required-star">*</span> : null}{' '}
          {label}
        </legend>
      )}
      <div className="radio-group-wrap">
        {options.map((o) => (
          <label key={o.value} className="custom-iptype1">
            <input
              disabled={disabled || o.disabled}
              type="radio"
              {...field}
              value={o.value}
              checked={o.value == String(meta.value)}
              onChange={(e) => {
                field.onChange(e);
                if (onChange) onChange(e.target.value);
              }}
            />
            <span>{o.label}</span>
          </label>
        ))}
      </div>
      <FieldError {...{ error, touched }} />
    </fieldset>
  );
};

export default wrapField(RadioGroupSimple);

import React from 'react';
import { useField } from 'formik';

export default function wrapField(WrappedComponent) {
  return (props) => {
    const { wrapperClassName, ...rest } = props;
    // Multiple validations
    const { validate } = props;
    let validateMultiple;
    if (Array.isArray(validate)) {
      validateMultiple = (val) => {
        for (let i = 0; i < validate.length; i++) {
          if (validate[i](val)) {
            return validate[i](val);
          }
        }
        return undefined;
      };
    }

    const { customClass } = props;
    const [field, meta, helpers] = useField({
      ...rest,
      ...(validateMultiple ? { validate: validateMultiple } : {}),
    });
    return (
      <div
        className={`${meta.error ? 'has-error' : ''} ${
          meta.touched ? 'touched' : ''
        } field-wrapper-inner ${customClass || ''} ${
          wrapperClassName || ''
        } ip-field-wrapper-${field.name}`}
      >
        <WrappedComponent
          field={field}
          meta={meta}
          helpers={helpers}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...rest}
        />
      </div>
    );
  };
}

import React from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import style from 'react-datepicker/dist/react-datepicker.css';
import wrapField from './wrapField';

import Label from './Label';

const dateField = ({
  input,
  label,
  placeholder,
  id,
  showTimeSelect,
  timeIntervals = 30,
  dateFormat,
  saveFormat,
  minDate,
  maxDate,
  showMonthYearPicker,
  showMonthDropdown,
  showYearDropdown,
  includeTimes,
  setTimeTo,
  disabled,
  showRequiredLabel,
  meta: { touched, error, warning },
  className = '',
}) => {
  const stateFormat = saveFormat || 'YYYY-MM-DD[T]HH:mm:ss[Z]';
  return (
    <div>
      <Label {...{ input, label, showRequiredLabel }} />
      <div>
        <DatePicker
          dateFormat={dateFormat || 'MM/dd/yyyy'}
          selected={
            input.value ? moment(input.value, stateFormat).toDate() : null
          }
          autoOk
          showTimeSelect={showTimeSelect}
          timeIntervals={timeIntervals}
          placeholderText={placeholder || 'Select a date'}
          id={id || `date-picker-${input.name}`}
          onBlur={() => input.onBlur()}
          onCalendarClose={() => input.onBlur()}
          onChange={(value) => {
            if (!(minDate && showTimeSelect && value < minDate)) {
              if (setTimeTo) {
                input.onChange(
                  value
                    ? moment(value)
                        .set({
                          hour: setTimeTo.get('hour'),
                          minute: setTimeTo.get('minute'),
                        })
                        .format(stateFormat)
                    : '',
                );
              } else {
                input.onChange(value ? moment(value).format(stateFormat) : '');
              }
            }
          }}
          minDate={minDate}
          maxDate={maxDate}
          includeTimes={includeTimes}
          disabled={disabled}
          showMonthYearPicker={showMonthYearPicker}
          showMonthDropdown={showMonthDropdown}
          showYearDropdown={showYearDropdown}
          dropdownMode="select"
          className={className}
        />

        {touched &&
          ((error && <div className="error">{error}</div>) ||
            (warning && <div>{warning}</div>))}
      </div>
    </div>
  );
};

export default wrapField(dateField);

import React from 'react';

const ConfirmDialogue = ({
  cancelText = 'Cancel',
  okText = 'OK',
  handleCancel,
  handleOk,
  hideFooter,
  children,
}) => (
  <div
    id="dialog"
    role="alertdialog"
    aria-modal="true"
    aria-labelledby="dialog_head"
    aria-describedby="dialog_desc"
    className="confirm-dialogue"
  >
    <div id="dialog_desc" className="confirm-desc">
      {children}
    </div>
    {!hideFooter && 
      <div className="footer-btn-wrap modal-footer">
        <button
          onClick={handleOk}
          className="btn btn-primary"
          type="button"
          style={{ minWidth: 200 }}
        >
          {okText}
        </button>
        <button
          onClick={handleCancel}
          className="btn btn-default"
          type="button"
          style={{ minWidth: 200 }}
        >
          {cancelText}
        </button>
      </div>
    }
  </div>
);

export default ConfirmDialogue;

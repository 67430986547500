import React, { useEffect, useState } from 'react';
import axios from 'axios';
import SecureMessageLogo from './SecureMessageLogo';

const LucetLogo = ({
  type = 'secure_message',
  logoType = 'white',
  showCaption = true,
  wrapperClasses = '',
}) => {
  const [logoDetails, setLogoDetails] = useState({});

  const loadMetadata = () => {
    axios.get(`/presentation_metadata`).then((r) => {
      if (r.data) {
        setLogoDetails(r.data);
      }
    });
  };

  useEffect(() => {
    loadMetadata();
  }, []);

  return logoDetails
    ? type === 'secure_message' && (
        <SecureMessageLogo
          logoDetails={
            logoType === 'white'
              ? logoDetails?.white_logo
              : logoDetails?.plum_logo
          }
          showCaption={showCaption}
          wrapperClasses={wrapperClasses}
        />
      )
    : null;
};
export default LucetLogo;

import React from 'react';
import wrapField from './wrapField';
import Label from './Label';

const textArea = ({
  input,
  label,
  placeholder,
  maxLength,
  maxLineBreaks,
  disabled,
  show_required_label,
  meta: { touched, error, warning, active },
  textOnly,
  elementRef,
  rightAccessory,
}) => {
  const length =
    input.value === undefined || input.value === null
      ? 0
      : String(input.value).length;
  const remainingLength = maxLength ? maxLength - length : null;
  return (
    <div>
      {textOnly ? (
        <React.Fragment>
          <Label
            {...{
              input,
              label,
            }}
          />
          <p
            className={`padding-bottom-sm ${input.value ? '' : 'no-info-text'}`}
            style={{ minHeight: '106px' }}
          >
            {input.value || 'No information provided'}
          </p>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div className="space-between">
            <Label
              {...{
                input,
                label,
                showRequiredLabel: show_required_label,
              }}
            />
            {maxLength && active && (
              <span
                className={remainingLength <= 20 ? 'text-danger' : ''}
              >{`Max ${maxLength} characters (${remainingLength} remaining)`}</span>
            )}
            {rightAccessory && (
              <span style={{ float: 'right' }}>{rightAccessory}</span>
            )}
          </div>
          <div>
            <textarea
              // {...input}
              value={input.value}
              onBlur={input.onBlur}
              onFocus={input.onFocus}
              onChange={(e) => {
                const val = e.target.value;
                if (
                  (maxLength && val.length > maxLength) ||
                  (maxLineBreaks &&
                    val.split(/\r\n|\r|\n/g).length > maxLineBreaks)
                ) {
                } else {
                  input.onChange(val);
                }
              }}
              maxLength={maxLength}
              placeholder={placeholder || 'Enter here'}
              className={`${
                remainingLength === 0 ? 'maxlength-reached' : ''
              } textarea-block`}
              rows="4"
              disabled={disabled}
              ref={elementRef}
            />

            {touched &&
              ((error && <span className="error">{error}</span>) ||
                (warning && <span>{warning}</span>))}
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default wrapField(textArea);

import React from "react"
import {useRef} from 'react';
import wrapField from './wrapField';
import {masks, nextCharIndex} from "../../helpers/moduleHelper";
import FieldError from "./FieldError";

const DateTextField = (props) => {
  const fieldRef = useRef();
  const {
    field,
    meta: {error, touched},
    type = 'text',
    placeholder,
    label,
    labelClass,
    inputClass,
    showRequiredLabel,
    autoComplete = 'off',
    setFieldValue,
    mask,
    name,
    min,
    max,
    disabled,
  } = props;
  return (
    <div className="dob-field">
      <fieldset>
        {label && (
          <legend className="prompt">
          <span>{showRequiredLabel ? <span className="required-star">* </span> : null}
            {label}</span>
          </legend>
        )}
        <div className="prompt">
          <input
            ref={fieldRef}
            {...field}
            inputMode="numeric"
            value={
              field.value || typeof field.value === 'number' ? field.value : ''
            }
            onChange={(e) => {
              if (mask && setFieldValue && name) {
                const value = e.target.value;
                const maskedValue = masks.dob(value, mask);
                maskAndUpdate(props, (maskedValue || mask), e, fieldRef)
              } else {
                field.onChange(e)
              }
            }}
            onFocus={(e) => {
              maskAndUpdate(props, (e.target.value || mask), e, fieldRef)
            }}
            onClick={(e) => {
              maskAndUpdate(props, (e.target.value || mask), e, fieldRef)
            }}
            onKeyDown={(e) => {
              if (e.keyCode === 37 || e.keyCode === 39 || e.keyCode === 8 || e.keyCode === 46) {
                e.preventDefault();
                if (e.keyCode === 8 || e.keyCode === 46) {
                  handleDelete(props, (e.target.value || mask), e, fieldRef);
                } else {
                  maskAndUpdate(props, (e.target.value || mask), e, fieldRef);
                }
              }
            }}
            placeholder={placeholder}
            type={type}
            className={inputClass}
            min={min}
            max={max}
            autoComplete={autoComplete}
            disabled={disabled}
          />
          <FieldError {...{ error, touched }} />
        </div>
      </fieldset>
    </div>
  );
};

const handleDelete = (props, value, e, fieldRef) => {
  // this only supports MM/DD/YYYY format
  if (props.mask && props.setFieldValue && props.name) {
    const cursorStart = e.target && e.target.selectionStart;
    const cursorEnd = e.target && e.target.selectionEnd;
    var valueAsArray = value.split("");
    var updateArray = [];
    valueAsArray.forEach((v, index) => {
      if (index >= cursorStart && index <= cursorEnd) {
        updateArray[index] = "-";
      } else {
        updateArray[index] = v;
      }
    });
    const maskedValue = masks.dob(updateArray.join(""), props.mask);
    props.setFieldValue(props.name, maskedValue);
    updateSelection(fieldRef, e, value);
  }
}

const maskAndUpdate = (props, value, e, fieldRef) => {
  // this only supports MM/DD/YYYY format
  if (props.mask && props.setFieldValue && props.name) {
    props.setFieldValue(props.name, value);
    updateSelection(fieldRef, e, value);
  }
}

const getCursorPosition = (keyCode, cursorPosition) => {
  // 37 (left arrow key), 39 (left arrow key), 8 (backspace key), 46 (delete key)
  return (keyCode === 37 || keyCode === 8 || keyCode === 46) ? (cursorPosition - 1) : (keyCode === 39) ? (cursorPosition + 1) : cursorPosition;
}

const updateSelection = (fieldRef, e, value) => {
  var cursorPosition = e.target && e.target.selectionStart; // starting position of the cursor
  const valueAsArray = value && value.split("");
  const keyCode = e.keyCode;
  if (valueAsArray) {
    if (e.type === "keydown") {
      // user has either pressed backspace/delete or left/right arrow keys so we want to update the cursor position as per logic
      cursorPosition = getCursorPosition(keyCode, cursorPosition);
    } else {
      // if selected char is a value dob value and not a mask value
      const firstAlphabetIndex = nextCharIndex(valueAsArray, "alphabet");
      if (firstAlphabetIndex !== null) {
        cursorPosition = firstAlphabetIndex;
      }
    }
    // filter cursor position
    const selectedChar = valueAsArray[cursorPosition];
    if (selectedChar === "/") {
      cursorPosition = getCursorPosition(keyCode, cursorPosition);
    }
    cursorPosition = (cursorPosition < 0) ? 0 : (cursorPosition >= valueAsArray.length) ? valueAsArray.length - 1 : cursorPosition;
    setTimeout(() => {
      // cursorPosition = getCursorPosition(keyCode, cursorPosition);
      fieldRef.current.setSelectionRange(cursorPosition, (cursorPosition + 1));
    }, 100);
  }
}

export default wrapField(DateTextField);

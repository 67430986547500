import React from 'react';

const ErrorPage = ({ errorMessage, detailedError, tridiuumLogo }) => (
  <div className="error-cont">
    <div className="powered-logo-wrapper" />
    <div className="error-msg">
      {errorMessage && (
        <div className="error-wrapper">
          <span>
            <img src="/assets/exclamation-circle.png" alt="" />
          </span>
          <h2>{errorMessage}</h2>
        </div>
      )}
    </div>
    <div className="form-wrapper" id="error-survey">
      <div className="symphony-logo-wrapper">
        <img
          src={
            tridiuumLogo
              ? `/assets/${tridiuumLogo.image}`
              : '/assets/polaris-landing-logo.png'
          }
          className={tridiuumLogo.class}
          alt="Tridiuum Logo"
          title={tridiuumLogo.title}
        />
      </div>

      {detailedError && (
        <div className=" error-msg">
          <div className=" error-wrapper">
            <h3>{detailedError}</h3>
          </div>
        </div>
      )}
    </div>
  </div>
);

export default ErrorPage;

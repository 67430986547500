import React from 'react';
import {formikAdapter} from "./formikAdapter";

export default function wrapperField(WrappedComponent) {
  return (props) => {
    let {input} = props; // Redux-Form (default)
    if (input == null) {
      // Formik
      return (
        <WrappedComponent
          {...props}
          {...formikAdapter(props)}
        />
      );
    } else {
      // Redux
      return (
        <WrappedComponent
          {...props}
        />
      )
    }
  }
}

import React from 'react';
import ConfirmDialogue from './ConfirmDialogue';
import AlertDialogue from './AlertDialogue';
import Modal from '../../shared/modal';

const ModalContext = React.createContext();

class ModalProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: [],
      promises: [],
    };
    this.resolver = React.createRef();
    this.unSetModal = this.unSetModal.bind(this);
  }

  setModal = (modal, config = {}) => {
    const { promises } = this.state;
    if (modal) {
      var modal_arr = this.state.modal.concat(modal);
      this.setState({
        modal: modal_arr,
      });
    }
    return new Promise((resolve) => {
      this.resolver.current = resolve;
      this.setState({
        promises: [...promises, resolve],
      });
    });
  };

  // Removes last modal from the modal array, effectively closing the top most modal.
  // The resolve function in the returned promise of setModal is fulfilled here by passing the feedback to it.
  unSetModal = (feedback) => {
    const { promises } = this.state;
    const modal_arr = this.state.modal;
    modal_arr.splice(modal_arr.length - 1, 1);
    promises[promises.length - 1](feedback);
    this.setState({
      modal: modal_arr,
      promises: promises.slice(0, -1),
    });
  };

  closeConfirmDialogue = (isConfirmed) => {
    this.unSetModal();
    if (this.resolver.current) {
      this.resolver.current(isConfirmed);
    }
  };

  confirmDialogue = (DialogueContent, config = {}) => {
    this.setModal(
      <ConfirmDialogue
        {...config}
        customProps={config && config.customProps}
        handleCancel={() => {
          this.closeConfirmDialogue(false);
        }}
        handleOk={() => {
          this.closeConfirmDialogue(true);
        }}
        hideFooter={config.hideFooter}
      >
        {DialogueContent}
      </ConfirmDialogue>,
    );
    return new Promise((resolve) => {
      this.resolver.current = resolve;
    });
  };

  alertDialogue = (DialogueContent, config = {}) => {
    this.setModal(
      <AlertDialogue
        {...config}
        customProps={config && config.customProps}
        handleOk={() => {
          this.closeConfirmDialogue(true);
        }}
        width={config.width}
      >
        {DialogueContent}
      </AlertDialogue>,
    );
    return new Promise((resolve) => {
      this.resolver.current = resolve;
    });
  };

  render() {
    const { children } = this.props;
    const {
      modal,
      // width,
    } = this.state;
    return (
      <ModalContext.Provider
        value={{
          unSetModal: this.unSetModal,
          setModal: this.setModal,
          confirmDialogue: this.confirmDialogue,
          alertDialogue: this.alertDialogue,
        }}
        {...this.props}
      >
        {children}
        {modal.length > 0 &&
          modal.map((m, i) => (
            <Modal
              customProps={m.props && m.props.customProps}
              closeModal={() => this.unSetModal()}
              key={i}
            >
              {m}
            </Modal>
          ))}
      </ModalContext.Provider>
    );
  }
}

const useModal = () => {
  const context = React.useContext(ModalContext);
  if (context === undefined) {
    throw new Error('useModal must be used within a ModalProvider');
  }
  return context;
};

const ModalConsumer = ModalContext.Consumer;

export { ModalContext, ModalProvider, useModal, ModalConsumer };

import React from 'react';
import {Form, Formik} from "formik";
import {TextField} from "../../../components/inputs";
import {masks, validations, csrfHeader} from "../../../../helperFunctions";
import {formValidations} from "../../../helpers/moduleHelper";
import axios from 'axios';


class ProtectedSiteLogin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {showNextButton: false}
  }

  handleSubmit = (values, url) => {
    const {pageName, submitURL, setLoader} = this.props;
    setLoader(true);
    return axios({
      method: 'post',
      url: url,
      data: {"protected_login": {login: values.login, password: values.password}},
      headers: csrfHeader,
    })
      .then(response => {
        if (response.status === 200 || response.status === 302) {
          window.location.href = response.request.responseURL;
        }
      })
      .catch(err => {
        setLoader(false);
        const msg =
          err.response.data && err.response.data.message
            ? err.response.data.message
            : err;
        alert(msg);
      });
  }

  validate = (values) => {
    const {locale} = this.props;
    const fieldErrorMessage = formValidations.fieldErrorMessage(locale);
    let errors = {};
    if (!values.login) {
      errors.login = fieldErrorMessage;
    }
    if (!values.password) {
      errors.password = fieldErrorMessage;
    }
    this.setState({showNextButton: Object.keys(errors).length === 0})
    return errors;
  }


  render() {
    const {pageName, submitURL, tridiuumLogo} = this.props;
    const {showNextButton} = this.state;
    return (
      <div className="protected-site-inner">
        <div className="form-wrapper wrapper-section">
          <div className="symphony-logo-wrapper">
            <img src={tridiuumLogo ? `/assets/${tridiuumLogo.image}` : "/assets/polaris-landing-logo.png"} alt="Tridiuum Logo" title={tridiuumLogo.title} className={tridiuumLogo.class}/>
          </div>
          <div className="message-wrapper">
            <p>You are trying to access the Lucet assessment from an unregistered computer. To register this
              computer’s IP Address, please enter your username and password. If you do not have a username and
              password, please contact your administrator. If you are a patient, please contact your provider. <br/> If
              you need additional assistance, please contact Lucet support or click the HELP button</p>
          </div>
        </div>

        <Formik
          initialValues={{
            login: '',
            password: ''
          }}
          onSubmit={(values, {setSubmitting}) => {
            setTimeout(() => {
              this.handleSubmit(values, submitURL).then(r => {

              })
              setSubmitting(false);
            }, 400);
          }}
          validate={this.validate}
        >
          {props => (
            <Form className="form-wrapper protected-site-cont">
              <div className={`rows`}>
                <div className="row clear">
                  <div className="form-group">
                    <TextField
                      name="login"
                      type="text"
                      placeholder="Username"
                      inputClass={`form-control`}
                    />
                  </div>
                </div>

                <div className="row clear">
                  <div className="form-group">
                    <TextField
                      name="password"
                      type="password"
                      placeholder="Password"
                      inputClass={`form-control`}
                    />
                  </div>
                </div>
                <div className="row clear">
                  <div className="form-group form-footer">
                    <button type="submit" className={`btn btn-lg btn-primary btn-block`} name=" action" id="
                                                            btn-next">Login to Register</button>
                  </div>
                </div>
              </div>

            </Form>
          )}
        </Formik>
      </div>

    );
  }
}

export default ProtectedSiteLogin;

import React, { useCallback, useEffect, useState } from 'react';
import { noop, updateLocale } from '../../helperFunctions';
import Message from './Message';

const MessageLive = ({
  type,
  mode = 'view',
  defaultLocale,
  handleLoadData = noop,
}) => {
  const [data, setData] = useState(null);
  const [locale, setLocale] = useState(defaultLocale);
  const [isLoading, setIsLoading] = useState(false);

  const { message, metadata } = data || {};

  const loadData = () => {
    setIsLoading(true);
    handleLoadData(locale).then((r) => {
      setIsLoading(false);
      if (r.data) {
        setData(r.data);
      }
    });
  };

  const handleChangeLocale = useCallback((newLocale) => {
    setLocale(newLocale);
    updateLocale(newLocale);
  }, []);

  useEffect(() => {
    loadData();
  }, [type, locale]);

  return (
    <Message
      {...{
        type,
        locale,
        mode,
        metadata,
        message,
        isLoading,
        handleChangeLocale,
      }}
    />
  );
};

export default MessageLive;

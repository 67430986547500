import React from 'react';
import axios from 'axios';
import {
  AsyncTypeahead,
  TypeaheadMenu,
  Menu,
  MenuItem,
} from 'react-bootstrap-typeahead';
import Label from './Label';

const headers = {
  'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
};

class multiTypeAhead extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      options: props.defaultSelected ? props.defaultSelected : [],
    };
  }

  focus = () => {
    this._typeahead.focus();
  };

  componentWillUpdate(nextProps) {
    if (nextProps.defaultSelected !== this.props.defaultSelected) {
      this.setState({ options: nextProps.defaultSelected });
      nextProps.input.onChange(nextProps.defaultSelected);
    }
  }

  render() {
    const {
      input,
      label,
      multiple,
      placeholder,
      labelKey,
      filterBy,
      defaultInputValue,
      defaultSelected,
      disabled,
      searchText,
      inputProps,
      meta: { touched, error, warning },
      customWrapperClass = '',
    } = this.props;
    const { searchParam, isLoading } = this.state;
    return (
      <div style={{ position: 'relative' }}>
        <Label {...{ input, label }} />
        <div className={customWrapperClass}>
          <AsyncTypeahead
            {...this.state}
            {...input}
            id={input.name}
            shouldSelect
            labelKey={labelKey}
            onFocus={() => {
              if (!input.value) this.setState({ searchParam: true });
            }}
            minLength={2}
            onSearch={this._handleSearch}
            onInputChange={this._handleInputChange}
            placeholder={placeholder || 'Start typing..'}
            renderMenu={(results, menuProps, state) => {
              // Hide the menu when there are no results.
              if (!results.length && isLoading) {
                return (
                  <Menu {...menuProps}>
                    <MenuItem disabled option={{}}>
                      {searchText || 'Searching...'}
                    </MenuItem>
                  </Menu>
                );
              }
              if (!results.length) {
                return (
                  <Menu {...menuProps}>
                    <MenuItem disabled option={{}}>
                      no results match “{state.text}”
                    </MenuItem>
                  </Menu>
                );
              }
              return React.createElement(TypeaheadMenu, {
                ...menuProps,
                labelKey: state.labelKey,
                options: results,
                text: state.text,
              });
            }}
            ref={(typeahead) => (this._typeahead = typeahead)}
            onBlur={() => {
              if (!input.value.length && this._typeahead) {
                this._typeahead.clear();
              }
              this.setState({ searchParam: false });
            }}
            filterBy={filterBy}
            defaultInputValue={defaultInputValue}
            disabled={disabled}
            defaultSelected={defaultSelected || []}
            selected={input.value === '' ? [] : input.value}
            onChange={(value) => {
              input.onChange(value);
            }}
            useCache={false}
            inputProps={inputProps}
            align="left"
            highlightOnlyResult
            multiple={multiple}
          />

          {touched &&
            ((error && <span className="error">{error}</span>) ||
              (warning && <span>{warning}</span>))}
          {searchParam && (
            <div
              className="rbt-menu-min dropdown-menu show"
              style={{ padding: '10px' }}
            >
              <span> Please enter 2 or more characters</span>
            </div>
          )}
        </div>
      </div>
    );
  }

  _handleSearch = (query) => {
    const { url, getOptions, request, params, input } = this.props;
    this.setState({ isLoading: true });
    if (request) {
      axios.post(`${url}`, { key: `${query}` }, { headers }).then((r) => {
        const options = getOptions ? getOptions(r) : r.data || [];
        const filteredOptions = input.value
          ? options.filter((o1) => !input.value.some((o2) => o1.id === o2.id))
          : options;
        this.setState({
          isLoading: false,
          options: filteredOptions,
        });
      });
    } else {
      axios({ method: 'get', url: `${url}${query}`, params }).then((r) => {
        const options = getOptions
          ? getOptions(r)
          : (r.data && r.data.data) || [];
        const filteredOptions = input.value
          ? options.filter((o1) => !input.value.some((o2) => o1.id === o2.id))
          : options;
        this.setState({
          isLoading: false,
          options: filteredOptions,
        });
      });
    }
  };

  _handleInputChange = (val) => {
    this.setState({ searchParam: val.length < 2 });
    if (val.length >= 2) this._handleSearch(val);
  };
}

export default multiTypeAhead;

import React from 'react';
import Label from './Label';

class CheckboxGroup extends React.Component {
  checkboxGroup() {
    const { label, options, input, showRequiredLabel, disabled } = this.props;
    return (
      <div className="checkbox-group">
        <Label {...{ input, label, showRequiredLabel }} />
        {options.map((option, index) => (
          <span key={option.value} className="checkbox-wrap">
            <input
              type="checkbox"
              id={`${input.name}-${option.value}`}
              name={`${input.name}[${index}]`}
              value={option.value}
              checked={input.value.indexOf(option.value) !== -1}
              disabled={disabled}
              onChange={(event) => {
                const newValue = [...input.value];
                if (event.target.checked) {
                  newValue.push(option.value);
                } else {
                  newValue.splice(newValue.indexOf(option.value), 1);
                }
                return input.onChange(newValue);
              }}
            />
            <label
              id={`field-label-${input.name}-${option.value}`}
              htmlFor={`${input.name}-${option.value}`}
            >
              {option.label}
            </label>
          </span>
        ))}
      </div>
    );
  }

  render() {
    return <div>{this.checkboxGroup()}</div>;
  }
}

export default CheckboxGroup;

/* eslint-disable react/jsx-props-no-spreading */
// To do: 1) Move the spinner to its own component 2) Modify it to support jsx children
import React, { useCallback, useState } from 'react';
import clsx from 'clsx';
import style from './utilities.module.scss';
import {
  arrayWithValuePopped,
  objectWithKeyPopped,
  isObjEmpty,
  mapObject,
} from '../../helperFunctions';

const UtilitiesContext = React.createContext();
const defaultLoaderKey = 1; // Used to fill the loader array.

const UtilitiesProvider = (props) => {
  const { children } = props;
  const [loadingQueue, setLoadingQueue] = useState([]);
  const queuePresent = loadingQueue.length > 0;
  const [messages, setMessages] = useState({});

  const showSpinner = useCallback(
    ({ key = defaultLoaderKey, displayMessage = null } = {}) => {
      setLoadingQueue((prevQueue) => [...prevQueue, key]);
      if (displayMessage)
        setMessages((prevMessages) => ({
          ...prevMessages,
          [key]: displayMessage,
        }));
    },
    [setMessages, setLoadingQueue],
  );

  const hideSpinner = useCallback(({ key = defaultLoaderKey } = {}) => {
    if (key === defaultLoaderKey) {
      setLoadingQueue((prevQueue) => [...arrayWithValuePopped(prevQueue, key)]);
    } else {
      setLoadingQueue((prevQueue) => [
        ...prevQueue.filter((value) => value !== key),
      ]);
    }
    setMessages((prevMessages) => objectWithKeyPopped(prevMessages, key));
  }, []);

  const setSpinner = useCallback(
    (show) => (show ? showSpinner() : hideSpinner()),
    [showSpinner, hideSpinner],
  );

  const renderedMessages = (
    <React.Fragment>
      {!isObjEmpty(messages) &&
        mapObject(messages, (key, message) => <span key={key}>{message}</span>)}
    </React.Fragment>
  );

  return (
    <UtilitiesContext.Provider
      value={{ showSpinner, hideSpinner, setSpinner }}
      {...props}
    >
      {children}
      {queuePresent && (
        <div className={style.spinner}>
          <div
            className={clsx(
              style.spinnerContent,
              !isObjEmpty(messages) && style.hasTextContent,
            )}
          >
            <div className={style.spinnerIcon} />
            {renderedMessages}
          </div>
        </div>
      )}
    </UtilitiesContext.Provider>
  );
};

function withUtilitiesProvider(WrappedComponent) {
  return (props) => (
    <UtilitiesProvider {...props}>
      <WrappedComponent {...props} />
    </UtilitiesProvider>
  );
}

const useUtilities = () => {
  const context = React.useContext(UtilitiesContext);
  if (context === undefined) {
    throw new Error('useUtilities must be used within a UtilitiesProvider');
  }
  return context;
};

const UtilitiesConsumer = UtilitiesContext.Consumer;

export {
  UtilitiesContext,
  UtilitiesProvider,
  useUtilities,
  UtilitiesConsumer,
  withUtilitiesProvider,
  defaultLoaderKey,
};

import clsx from 'clsx';
import React from 'react';
import styles from './LucetLogo.module.scss';

const SecureMessageLogo = ({
  logoDetails,
  wrapperClasses,
  showCaption = true,
}) => {
  const { image, class: imageClass, caption } = logoDetails || {};
  return (
    <div
      className={clsx(styles.lucetLogoWrapper, wrapperClasses)}
      aria-label={`Lucet Logo ${showCaption ? caption : ''}`}
      aria-hidden="false"
      role="img"
    >
      <img
        className={imageClass || 'lucet-default-login-logo'}
        alt=""
        src={`/assets/${image}`}
        id="lucet-logo"
      />
      {showCaption && caption && <span>{caption}</span>}
    </div>
  );
};

export default SecureMessageLogo;

import React from 'react';
import Label from './Label';

class LabelField extends React.Component {
  render() {
    const { input, label, showRequiredLabel } = this.props;

    return (
      <div>
        <Label {...{ input, label, showRequiredLabel }} />
      </div>
    );
  }
}

export default LabelField;

import React from 'react';
import wrapField from './wrapField';
import Label from './Label';

const RadioGroup = ({
  input,
  meta,
  label,
  options,
  disabled,
  showRequiredLabel,
  customWrapperClass = '',
}) => {
  const hasError = meta.touched && meta.error;

  return (
    <div>
      <Label {...{ input, label, showRequiredLabel }} />
      <div className={`radio-group-wrap ${customWrapperClass}`}>
        {options.map((o) => (
          <label
            id={`field-label-${input.name}-${o.value}`}
            key={o.value}
            className={`custom-iptype1 ${o.icon ? 'has-icon' : 'no-icon'}`}
            htmlFor={`field-${input.name}-${o.value}`}
          >
            <input
              id={`field-${input.name}-${o.value}`}
              disabled={disabled || o.disabled}
              type="radio"
              {...input}
              value={o.value}
              checked={o.value === String(input.value)}
            />
            <span className={o.className} title={o.title}>
              {o.icon && <img alt="" src={o.icon.src} style={o.icon.style} />}
              {o.label}
            </span>
          </label>
        ))}
      </div>
      {hasError && <div className="error">{meta.error}</div>}
    </div>
  );
};

export default wrapField(RadioGroup);
